import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const SectionContainer = ({ article }) => {
    const { image, headline, text } = article

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const textAnime = {
        initial: {
            y: 10,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    }

    return (
        <section css={section}>
            <div css={parallaxContainer}>
                <Parallax y={[-12, 12]} tagOuter="div">
                    <GatsbyImage image={getImage(image)} alt="concept image" css={parallaxImage} />
                </Parallax>
            </div>
            <motion.div
                css={textBox}
                ref={ref}
                initial="initial"
                animate={controls}
                transition={transition}
                variants={textAnime}
            >
                {headline && <h4>{headline}</h4>}
                {text && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: text.replace(/\n/g, "<br>"),
                        }}
                    />
                )}
            </motion.div>
        </section>
    )
}

const ConceptPageSection = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulProductPage {
                nodes {
                    section1Headline
                    section1Description {
                        section1Description
                    }
                    section1 {
                        gatsbyImageData
                    }
                    section2Headline
                    section2Description {
                        section2Description
                    }
                    section2 {
                        gatsbyImageData
                    }
                    section3Headline
                    section3Description {
                        section3Description
                    }
                    section3 {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulProductPage.nodes[0] : data.allContentfulProductPage.nodes[1]

    const articles = [
        {
            id: "article-1",
            image: content.section1,
            headline: content.section1Headline,
            text: content.section1Description.section1Description,
        },
        {
            id: "article-2",
            image: content.section2,
            headline: content.section2Headline,
            text: content.section2Description.section2Description,
        },
        {
            id: "article-3",
            image: content.section3,
            headline: content.section3Headline,
            text: content.section3Description.section3Description,
        },
    ]

    return (
        <ParallaxProvider>
            <div css={wrapper}>
                {articles.map((article) => (
                    <SectionContainer key={article.id} article={article} />
                ))}
            </div>
        </ParallaxProvider>
    )
}
export default ConceptPageSection

const wrapper = css`
    margin-bottom: 20vh;
    @media ${device.sp} {
        margin-bottom: 10vmax;
    }

    section:nth-of-type(2) {
        flex-direction: row-reverse;
    }
`
const section = css`
    display: flex;
    align-items: center;
    margin-bottom: 25vh;

    @media ${device.sp} {
        display: block;
        margin-bottom: 8vmax;
    }
`
const textBox = css`
    width: 30%;
    padding: 0 5%;

    @media ${device.sp} {
        width: 80%;
        padding: 5vmax 10%;
    }

    h4 {
        margin-bottom: 2rem;
    }
`
const parallaxContainer = css`
    width: 60%;
    height: 52vh;
    overflow: hidden;
    @media ${device.sp} {
        width: 100%;
        height: 40vh;
    }

    .parallax-outer,
    .parallax-inner {
        height: 100% !important;
    }
`
const parallaxImage = css`
    height: 100% !important;
`
