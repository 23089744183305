import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"

const ConceptPageFirstView = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulProductPage {
                nodes {
                    heroImage {
                        gatsbyImageData
                    }
                    headDescription {
                        headDescription
                    }
                    productPageTitle
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulProductPage.nodes[0] : data.allContentfulProductPage.nodes[1]

    return (
        <div css={wrapper}>
            <GatsbyImage image={getImage(content.heroImage)} alt="concept image" objectFit="contain" css={heroImage} />
            <div css={textBox}>
                <h6>{content.productPageTitle}</h6>
                <p
                    dangerouslySetInnerHTML={{
                        __html: content.headDescription.headDescription.replace(/\n/g, "<br>"),
                    }}
                    css={desc}
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: content.headDescription.headDescription.replace(/\n/g, ""),
                    }}
                    css={descSp}
                />
            </div>
        </div>
    )
}
export default ConceptPageFirstView

const wrapper = css`
    display: block;
    padding: 10vh 0;
    @media ${device.sp} {
        padding: 5vmax 0;
    }
`
const heroImage = css`
    width: 70vw;
    max-height: 60vh;
    margin: 0 auto 10vh;
    display: block !important;
    @media ${device.sp} {
        width: 100%;
        margin: 0 auto 8vmax;
    }
`
const textBox = css`
    display: block;
    width: clamp(300px, 50%, 1000px);
    margin: 0 auto 10vw;
    h6 {
        text-align: center;
        margin-bottom: 2rem;
    }

    @media ${device.tb} {
        width: 80%;
    }
    @media ${device.sp} {
        margin-bottom: 14vmax;
    }
`
const desc = css`
    text-align: center;
    @media ${device.tb} {
        display: none;
    }
`
const descSp = css`
    display: none;
    text-align: left;
    @media ${device.tb} {
        display: block;
    }
`
