import * as React from "react"
import { Layout } from "../components/layout"
import ConceptPageFirstView from "../components/conceptPageFirstView"
import ConceptPageSection from "../components/conceptPageSection"
import ShopSection from "../components/shopSection"

const ConceptPage = () => {
    return (
        <Layout>
            <ConceptPageFirstView />
            <ConceptPageSection />
            <ShopSection />
        </Layout>
    )
}
export default ConceptPage
